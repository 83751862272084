import { StripeSubscriptionClass } from '@/modules/subscription/types'
import { Namespace } from '@/store/types'
import { DateFormatter } from '@/utils/date-formatter'
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

declare var consoleLog: any

@Component({})
export default class SubscriptionTracking extends Vue {
  @State('subscription', { namespace: Namespace.App }) public subscription!: StripeSubscriptionClass

  @Action('setCancelSubscriptionAtEnd', { namespace: Namespace.App }) public setCancelSubscriptionAtEnd: any
  @Action('getSubscriptionWithInvoice', { namespace: Namespace.App })
  public getSubscriptionWithInvoice: any

  public isLoadingSubscription: boolean = true

  get isSubscriptionAboutToCancel() {
    return this.subscription.cancel_at_period_end
  }

  get subscriptionCancelOrResumeMsg() {
    return this.isSubscriptionAboutToCancel ? 'Resume subscription' : 'Cancel subscription'
  }

  get billingEndDisplay() {
    // current_period_end is a unix timestamp, in seconds.
    return DateFormatter.formatSubscriptionDate(this.subscription.current_period_end) // Eg "Tomorrow at 10:12 AM" or "Sunday at 2:30 AM" or if longer than 1 week away "7/10/2020"
  }

  public async toggleCancelSubscription(tenantId: number) {
    this.isLoadingSubscription = true

    var isCancelling = !this.subscription.cancel_at_period_end // This is effectively a toggle for cancelling/resuming
    if (await this.setCancelSubscriptionAtEnd({
      stripeSubscriptionId: this.subscription.id,
      changedBy: this.$auth0.user.email,
      isCancelAtPeriodEnd: isCancelling,
      tenantId: tenantId,
    })) {
      consoleLog(`Set cancel to ${isCancelling}`)
    } else {
      consoleLog(`Failed to set cancel to ${isCancelling}`)
    }
    this.isLoadingSubscription = false
  }
}
